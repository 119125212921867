﻿/// <reference path="../main.scss" />
/**
    Variables Base
**/


$font-family-sans-serif: 'Roboto', sans-serif !default;
$font-family-sans-serif-condensed: 'Roboto Condensed', sans-serif !default;
$font-size-base: 14px;
$font-size-base-headlines: 12px;
$font-size-h1:            floor(($font-size-base-headlines * 3)) !default; // ~36px
$font-size-h2:            floor(($font-size-base-headlines * 2)) !default; // ~24px
$font-size-h3:            ceil(($font-size-base-headlines * 1.5)) !default; // ~18px
$font-size-h4:            ceil(($font-size-base-headlines * 1.5)) !default; // ~18px
$font-size-h5:            ceil(($font-size-base-headlines * 1.33)) !default; // ~16px 
$font-size-h6:            ceil(($font-size-base-headlines * 1)) !default; // ~12px

$icon-font-path: "../bootstrap-sass-master/assets/fonts/bootstrap/" !default;
$slick-font-path: "../slick-1.6.0/slick/fonts/" !default;

$color-accent: #D81921;
$color-accent-dark: #be1921;
$color-accent-dark-extra: #95232A;
$color-black: #111;   
$color-black-light: #444; 
$color-gray-dark: #888;  
$color-gray: #DDD;
$color-gray-2: #D0D0D0; 
$color-gray-light: #EEE; 
$color-gray-light-light: #ECECEC;
$color-white: #FFF;