﻿/// <reference path="../print.scss" />
/**
    Print
**/
a:after {
    content: " (" attr(href) ") ";
    font-size: 0.8em;
    font-weight: normal;
}
.logo img {
    background-color: white;
    padding: 5px;
    -webkit-print-color-adjust: exact;
}
.logo, .breadcrumb li a {
    &:after{
        display: none;
    }
}

.navbar-collapse, .dropdown-menu, .dropdown, .sr-only, button, .carousel-img-list, .slick-arrow, .share-block, .icon,
.sidebar-box, .modal, .pagelinkcarouselblock, .product-overview, footer.hidden-print, .btn, .visible-sm, .visible-xs,
.page-banner-block-inner, .videoblock, .navigationcarouselblock, .quotecarouselblock, .right-navigation, .rawhtmlblock, .pagelistblock,
#epi-quickNavigator, #systemtextadmin {
    display: none !important;
}

.main-header, footer.visible-print {
    color:$color-white;
    background-color: $navbar-default-bg;
    -webkit-print-color-adjust: exact;
    padding: 1em;
}

.breadcrumb {
    margin-top: 0;
    -webkit-print-color-adjust: exact;
}

footer.visible-print {
    margin-top: 1em;
}

.pagebannerblock img {
    max-width: 100%;
    max-height: 300px;
}

.standard-page img {
    /*max-width: 100%;*/
    max-height: 300px;
}

a, a:visited, a:active, a:hover{
    color: #000;
}