@charset "UTF-8";
/**
    Variables Base
**/
/**
    Variables
**/
.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 2px; }
  .breadcrumb > li {
    display: inline-block; }
    .breadcrumb > li + li:before {
      content: "/ ";
      padding: 0 5px;
      color: #ccc; }
  .breadcrumb > .active {
    color: #777777; }

/**
    Print
**/
a:after {
  content: " (" attr(href) ") ";
  font-size: 0.8em;
  font-weight: normal; }

.logo img {
  background-color: white;
  padding: 5px;
  -webkit-print-color-adjust: exact; }

.logo:after, .breadcrumb li a:after {
  display: none; }

.navbar-collapse, .dropdown-menu, .dropdown, .sr-only, button, .carousel-img-list, .slick-arrow, .share-block, .icon,
.sidebar-box, .modal, .pagelinkcarouselblock, .product-overview, footer.hidden-print, .btn, .visible-sm, .visible-xs,
.page-banner-block-inner, .videoblock, .navigationcarouselblock, .quotecarouselblock, .right-navigation, .rawhtmlblock, .pagelistblock,
#epi-quickNavigator, #systemtextadmin {
  display: none !important; }

.main-header, footer.visible-print {
  color: #FFF;
  background-color: #D81921;
  -webkit-print-color-adjust: exact;
  padding: 1em; }

.breadcrumb {
  margin-top: 0;
  -webkit-print-color-adjust: exact; }

footer.visible-print {
  margin-top: 1em; }

.pagebannerblock img {
  max-width: 100%;
  max-height: 300px; }

.standard-page img {
  /*max-width: 100%;*/
  max-height: 300px; }

a, a:visited, a:active, a:hover {
  color: #000; }
